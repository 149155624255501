"use client";

import { useEffect, useState, useContext } from "react";
import { GAEvent, GA_EVENTS } from "@/helpers/gaEvents";
import styles from "./SideButtons.module.scss";
import cn from "clsx";
import { pushToDataLayer } from "@/helpers/gtag";
import CommonButton from "@/components/common/Analytics/CustomButton";
import { IModalActions, ModalTypes } from "@/types/modal";
import { getGlobalStateValue, updateStateSession } from "@/context/utils";
import { ModalContext } from "@/context/ModalContext";

const SideButtons = ({ data, isMobile = false, modalType }) => {
  const { modalDispatch } = useContext(ModalContext);
  const { STICKY_CTA_TEL, STICKY_CTA_WHATSAPP, CALL_CHAT_INTERACTION } = GAEvent;

  const [isVisible, setIsVisible] = useState(false);

  const res = data?.filter((x, i) => x?.enabled && i !== 0);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.location.pathname === "/") {
        if (window.scrollY > 0.7 * window.innerHeight) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const handleClick = (item) => {
    const itemUrl = item?.url || "#";
    if (itemUrl.includes("tel")) {
      // for tel
      const gaData = GA_EVENTS.get(STICKY_CTA_TEL);
      pushToDataLayer(gaData);
      const gaData2 = {
        ...GA_EVENTS.get(CALL_CHAT_INTERACTION),
        cta_text: "call_us",
        section_name: "sticky_sidetray",
        page_type: getGlobalStateValue("pageType"),
      };
      pushToDataLayer(gaData2);
    } else if (itemUrl.includes("whatsapp")) {
      // for whatsapp
      const gaData = GA_EVENTS.get(STICKY_CTA_WHATSAPP);
      pushToDataLayer(gaData);

      const gaData2 = {
        ...GA_EVENTS.get(CALL_CHAT_INTERACTION),
        cta_text: "whatsapp_chat",
        section_name: "sticky_sidetray",
        page_type: getGlobalStateValue("pageType"),
      };
      pushToDataLayer(gaData2);
    }
  };

  const renderItemUrl = (itemUrl) => {
    return itemUrl.includes("tel") && !isMobile ? "#" : itemUrl;
  };

  const fireGTOpenCTA = ()  => {
    
    pushToDataLayer({
      "event":"lead_initiate",
      "cta_text": "Apply Now",
      "section_name":"side_widget",
      "header_title": "header_title",
      "page_type": getGlobalStateValue("pageType"),
      "page_url": location.pathname
    });

    updateStateSession({
      sectionTitle: "header_title",
      leadStartingCta: "Apply Now",
      parentSection: "side_widget"
    })

    modalDispatch({
      type: IModalActions.OpenModal,
      data: {
        modalType,
      },
    });
  }

  return (
    <>
      <div className={styles.sideBtnRoot}>
        <div className={`sideButtons flex flex-col ${styles.buttonPosition} ${styles.btnWrapper}`}>
          {res?.map((item, i) => (
            <div className={styles.imgSize} key={i}>
              {item.title === "call-button" && !isMobile ? (
                <CommonButton
                  GADataNew={{
                    ...GA_EVENTS.get(CALL_CHAT_INTERACTION),
                    cta_text: "call_us",
                    section_name: "sticky_sidetray",
                    page_type: getGlobalStateValue("pageType"),
                  }}
                  isEventOnly={true}
                  type={"button"}
                  modalType={ModalTypes.CallUsModal}
                  bg="o"
                  color="b"
                  className={styles.stickCTAbtn}
                >
                  <img
                    id="call-icon"
                    className={cn("aspect-square", styles.imgIconSize)}
                    src={item?.source?.data?.[0]?.attributes?.url}
                    alt={item?.alt || ""}
                    loading="lazy"
                  />
                </CommonButton>
              ) : (
                <a
                  href={renderItemUrl(item?.url) || "#"}
                  onClick={() => handleClick(item)}
                  target={item?.target?.[0] || "_self"}
                >
                  <img
                    id="wa-icon"
                    className={cn("aspect-square", styles.imgIconSize)}
                    src={item?.source?.data?.[0]?.attributes?.url}
                    alt={item?.alt || ""}
                    loading="lazy"
                  />
                </a>
              )}
            </div>
          ))}
          {isVisible && (
            <div className={styles.stickyApplymob}>
              <div
                onClick={fireGTOpenCTA}
                className={styles.stickyApplyBtn}
              >
                APPLY NOW
              </div>
            </div>
          )}
        </div>
      </div>
      {isVisible && <div className={styles.stickyApply}>
        <div
          onClick={fireGTOpenCTA}
          className={styles.stickyApplyBtn}
        >
          APPLY NOW
        </div>
      </div>}
    </>
  );
};
export default SideButtons;
