"use client";

import React, { Suspense, useCallback, useEffect, useRef, useState } from "react";
import styles from "./EnrollButton.module.scss";
import cn from "clsx";
import CommonButton from "@/components/common/Analytics/CustomButton";
import { GA_EVENTS, GAEvent } from "@/helpers/gaEvents";
import { usePathname } from "next/navigation";
import { ModalTypes } from "@/types/modal";
import { getGlobalStateValue } from "@/context/utils";

const EnrollButton = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);
  const pathName = usePathname()

  const toggleVisibility = () => {
    if (window.location.pathname === "/") {
      if (window.scrollY > 0.7 * window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    } else {
        setIsVisible(true);
    }
  }

  useEffect(() => {
    if (data?.CTA?.enabled && window.innerWidth < 768) {
      toggleVisibility();
      window.addEventListener("scroll", toggleVisibility);
    }

    if(window.location.pathname !== "/" && document.getElementById("new-lead-form")){
      const currentRef = document.getElementById('enroll-now-cta')
      currentRef.style.display = "none"
    }

 
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  const { LEAD_INITIATE } = GAEvent;
 
  return (
    <>
    {pathName!== "/predegree" && <div id="enroll-now-cta" className={cn({ [styles.root]: isVisible })}>
        <CommonButton
          isEventOnly={true}
          GADataNew={{
            ...GA_EVENTS.get(LEAD_INITIATE),
            cta_text: data?.CTA?.text || "NA",
            section_name: "Mobile Footer",
            header_title: "NA",
            page_type: getGlobalStateValue('pageType'),
            page_url : true
          }}
          onClickStorePayload={
            {
              parentSection : "Mobile Footer"
            }
          }
          className={cn("scrollbtn scrollButton", styles.scrollButton, {
            [styles.show]: isVisible,
            ["!hidden"]: !isVisible,
          })}
          href="#"
          type="button"
          modalType={ModalTypes.ConnectModal}
          modalData={{ associatedPrograms: data.associatedPrograms, isLandingPage : true }}
          size="l"
          bg="s"
          color="h" // when bg banner is white, button bg is yellow & hover is white
          icon={"/_a/icons/left-arrow.svg"}
        >
          {data?.CTA?.text}
        </CommonButton>
    </div>}
    </>    
  );
};

export default EnrollButton;
